<template>
  <base-material-card
    :title="$t('dashboard')"
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12" xl="10" class="pt-0">
        <admin-dashboard v-if="$store.getters['auth/isAdmin']" />
        <tattooer-dashboard v-if="$store.getters['auth/isTattooer']" />
        <studio-dashboard v-if="$store.getters['auth/isStudio']" />
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
export default {
  name: "Dashboard",
  components: {
    AdminDashboard: () => import("@/components/admin/Dashboard"),
    TattooerDashboard: () => import("@/components/tattooer/Dashboard"),
    StudioDashboard: () => import("@/components/studio/Dashboard"),
  },
};
</script>
